import React from 'react';
import Sidebar from '../components/dashboardContent/Sidebar';
import AllFichesTechniques from './dashboardContent/AllFichesTechniques';
import FicheTechnique from './dashboardContent/FicheTechnique';
import Form from '../components/dashboardContent/Form';
import Report from '../components/dashboardContent/Report';

import Image1 from '../Images/Photo1.PNG';
import '../StylesCSS/Dashboard.css';
import { useState } from 'react';


const Dashboard = ({ handleLogout }) => {
  const [activeSection, setActiveSection] = useState('form');

  const renderSection = () => {
    switch (activeSection) {
      case 'AllFichesTechniques':
        return <AllFichesTechniques />;
      case 'FicheTechnique':
        return <FicheTechnique/>;
      case 'form':
        return <Form />;
      case 'Report':
        return <Report/>
      default:
        return <Form />;
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh" }} className="d-flex" id="wrapper">
      <Sidebar setActiveSection={setActiveSection} handleLogout={handleLogout} />
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img src={Image1} alt="Dashboard Header" style={{ width: '400px', height: '100px' }} />
          </div>
          {renderSection()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
