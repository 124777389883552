// src/utils/activityFields.js

export const activityFields = {
  'تقديم خدمة الإعلام المدرسي والمهني': [
    'الافاق الدراسية والتكوينية ما بعد البكالوريا',
    'الدراسة بالخارج',
    'تحيين سبورة النشر والملصقات بدعائم إعلامية محينة حول الدراسة بالخارج',
    'مواعيد إجراء المقابلات والاختبارات اللغوية',
    'الوثائق الضرورية لإكمال ملف التسجيل والحصول على التأشيرة',
    'تحيين سبورة النشر والملصقات بدعائم إعلامية حول إمكانات إعادة التوجيه المتاحة والآجال',
    'التفاعل مع استفسارات التلاميذ وأوليائهم حول إمكانات إعادة التوجيه والافاق الدراسية ما بعد مستواهم الدراسي حضوريا وعن بعد',
    'التفاعل مع استفسارات التلاميذ وأوليائهم حضوريا وعن بعد حول إمكانات الاستعطاف والطرق الكفيلة لاستدراك السنة الدراسية',
    'الافاق الدراسية والتكوينية ما بعد الثالثة ثانوي إعدادي',
    'الافاق الدراسية والتكوينية ما بعد الجذوع المشتركة',
    'الافاق الدراسية والتكوينية ما بعد الأولى بكالوريا'
  ],
  'تقديم خدمة الاستشارة': [
    'استشارة حول نظام التقويم الدراسي',
    'استشارة حول الشعب والمسالك',
    'استشارة حول المعاهد والمدارس والكليات...',
    'استشارة حول الدراسة بالخارج'
  ],
  'تقديم خدمة المواكبة النفسية و الاجتماعية': [
    // Ajoutez les activités spécifiques ici si nécessaire
  ],
  'إبداء الرأي في اختيارات المتعلمين الدراسية والتكوينية': [
    'ابداء الرأي في اختيارات تلاميذ الثالثة إعدادي -المرحلة الأولية-',
    'ابداء الرأي في اختيارات تلاميذ الثالثة إعدادي -المرحلة النهائية-',
    'ابداء الرأي في اختيارات تلاميذ الجذوع المشتركة -المرحلة النهائية-',
    'ابداء الرأي في اختيارات تلاميذ الأولى باك -المرحلة النهائية-',
    'ابداء الرأي في اختيارات التلاميذ -الشعب الخاصة-'
  ],
  'تقديم الدعم التقني لمؤسسات القطاع لإدماج مكون التوجيه ضمن آليات اشتغالها': [
    'ادماج مكون التوجيه ضمن مشروع المؤسسة'
  ],
  'تنسيق تدخلات الفاعلين': [
    'التنسيق مع اطر الإدارة التربوية بمؤسسات القطاع',
    'التنسيق مع اطر التوجيه التربوي بالمديرية',
    'التنسيق مع مصالح المديرية والمركز الجهوي للتوجيه المدرسي والمهني'
  ],
  'اجتماعات و مجالس': [
    'اجتماع على صعيد المديرية الاقليمية',
    'مجالس القسم',
    'المجلس التربوي',
    'مجلس التدبير',
    'اخر'
  ],
  'تقديم الاستشارة للجن ومجالس التوجيه وإعادة التوجيه': [
    'اعداد بطاقات الترشيح لكل من المستعطفين أو التلاميذ الراغبين في إعادة التوجيه',
    'دراسة ملفات التلاميذ المستعطفين من حيث الشروط',
    'تجميع نقط المواد المؤهلة لتلاميذ إعادة التوجيه من اجل الانتقاء',
    'التأشير على الطلبات المستوفية للشروط المطلوبة'
  ],
  'مجالات أخرى': [
    // Ajoutez les activités spécifiques ici si nécessaire
  ]
};
