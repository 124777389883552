// src/config/apiConfig.js
import axios from 'axios';

// Crée une instance d'axios avec l'URL de base
const API_BASE_URL = axios.create({
    baseURL: 'https://al-bawsala-backend-production-f68c.up.railway.app/api', 
    //baseURL: 'http://localhost:8082/api', 
    headers: {
        'Content-Type': 'application/json',
    },
});

export default API_BASE_URL;
