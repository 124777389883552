// src/api/formApi.js
import API_BASE_URL from '../config/apiConfig';

export const submitForm = (formData) => {
    console.log("FormData to be sent:", formData);
    return API_BASE_URL.post('/forms/register', formData);
};

export const getFormByUserId = async (userId) => {
    const response = await API_BASE_URL.get(`/forms/myform/${userId}`);
    return response.data;
};

export const updateForm = (userId, formData) => {
    return API_BASE_URL.put(`/forms/updateMyform/${userId}`, formData);
};
