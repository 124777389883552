// src/api/userApi.js
import API_BASE_URL from '../config/apiConfig';

export const registerUser = (user) => {
    return API_BASE_URL.post('/users/register', user);
};

export const loginUser = (credentials) => {
    return API_BASE_URL.post('/users/login', credentials);
};
