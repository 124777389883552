// Liste des domaines d’intervention
export const interventionFields = [
    'تقديم خدمة الإعلام المدرسي والمهني',
    'تقديم خدمة الاستشارة',
    'تقديم خدمة المواكبة النفسية و الاجتماعية',
    'إبداء الرأي في اختيارات المتعلمين الدراسية والتكوينية',
    'تقديم الدعم التقني لمؤسسات القطاع لإدماج مكون التوجيه ضمن آليات اشتغالها',
    'تنسيق تدخلات الفاعلين',
    'تقديم الاستشارة للجن ومجالس التوجيه وإعادة التوجيه',
    'اجتماعات و مجالس',
    'مجالات أخرى',
  ];
  