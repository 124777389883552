import React, { useState } from 'react';
import { loginUser } from '../api/userApi';
import { useUser } from '../context/UserContext';

import '../styles.css';
import imageSchool from '../Images/Photo1.PNG';

const Log = ({ setAuth, showRegister }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUserId } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser({ email, password });
      console.log('API response:', response);
      if (response.data) {
        setAuth(true);
        setUserId(response.data.id); // Enregistre l'ID utilisateur
        console.log("userId set to:", response.data.id);
      } else {
        alert("Email ou mot de passe incorrect.");
      }
    } catch (error) {
      console.error(error);
      alert("Erreur lors de la connexion.");
    }
  };
  
  
  

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-xl-5 ms-xl-auto px-lg-4 text-center text-primary">
          <img 
            className="img-fluid mb-4" 
            width="350" 
            src={imageSchool}            
            alt="" 
            style={{ transform: "rotate(0deg)" }} 
          />
          <h1 className="mb-4">Bienvenue dans votre <br className="d-none d-lg-inline" /> espace privé.</h1>
          <p className="text-muted arabic-text">  البوصلة هي منصة رقمية موجهة لمستشاري التوجيه التربوي . هي بمثابة لوحة قيادة للمساعدة على تنظيم ، تبسيط و أرشفة المهام المنجزة على شكل تقارير يومية (بطاقات تقنية) شهرية او دورية .  </p>
          <p className="text-muted arabic-text"> سنعمل على تطوير البوصلة ليصبح بمقدورها مساعدتك على تدبير مجالس الاقسام في نهاية السنة الدراسية و تقديم جميع الاحصائيات و المحاضر المطلوبة .</p>
        </div>
        <div className="col-lg-6 px-lg-4">
          <div className="card">
            <div className="card-header px-lg-5">
              <div className="card-heading text-primary">Se connecter</div>
            </div>
            <div className="card-body p-lg-5">
              <h3 className="mb-4">Bienvenue</h3>
              <p className="text-muted text-sm mb-5">Connectez-vous pour accéder à toutes les fonctionnalités de .</p>
              <form onSubmit={handleLogin}>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="floatingInput"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@example.com"
                    required
                  />
                  <label htmlFor="floatingInput">Email</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="floatingPassword"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                    required
                  />
                  <label htmlFor="floatingPassword">Mot de passe </label>
                </div>
                <button className="btn btn-primary" type="submit">Se Connecter</button>
              </form>
            </div>
            <div className="card-footer px-lg-5 py-lg-4">
              <div className="text-sm text-muted">
                Pas encore membre ? 
                <button 
                  onClick={showRegister} 
                  className="btn btn-link p-0" 
                  style={{ textDecoration: 'underline', color: '#0d6efd' }}>
                  Inscrivez-vous
                </button>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Log;
