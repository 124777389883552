import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useUser } from '../../context/UserContext';
import '../../StylesCSS/buttons.css';


const Report = () => {
  const API_BASE_URL = 'https://al-bawsala-backend-production-f68c.up.railway.app';
 // const API_BASE_URL = 'http://localhost:8082';
  const [showMonthlyReport, setShowMonthlyReport] = useState(false);
  const [showPeriodicReport, setShowPeriodicReport] = useState(false);
  const [selectedYearMonthly, setSelectedYearMonthly] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYearPeriodic, setSelectedYearPeriodic] = useState('');
  const [selectedCycle, setSelectedCycle] = useState('');
  const { userId } = useUser(); // Récupérer l'ID utilisateur du contexte
  console.log("monthly : ", selectedMonth , "year :" , selectedYearMonthly);
  console.log("cycle : ", selectedCycle , "year :" , selectedYearPeriodic);

  const monthsInArabic = {
    '1': 'يناير',
    '2': 'فبراير',
    '3': 'مارس',
    '4': 'أبريل',
    '5': 'مايو',
    '6': 'يونيو',
    '7': 'يوليوز',
    '8': 'غشت',
    '9': 'شتنبر',
    '10': 'أكتوبر',
    '11': 'نونبر',
    '12': 'دجنبر'
  };

  

  const years = Array.from({ length: 101 }, (_, index) => (2000 + index).toString());
  const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString());
  const cycles = ['الدورة الأولى', 'الدورة التانية'];

  const handleExportPDF1 = async () => {
    try {
      // Effectuer une requête pour récupérer les données depuis l'API
      const response = await fetch(
        `${API_BASE_URL}/api/fiche-technique/pdf/monthly-report?userId=${userId}&year=${selectedYearMonthly}&month=${selectedMonth}`
      );
      const responseData = await response.json();

      // Créer le PDF
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      doc.addFileToVFS('NotoSansArabic-Regular.ttf', '/fonts/NotoSansArabic-Regular.ttf');
      doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
      // Charger l'image
      const img = new Image();
      img.src = '/images/Photo1.PNG';
      await new Promise(resolve => {
         img.onload = resolve;
       });

      const imgWidth = 100;
      const imgHeight = (img.height * imgWidth) / img.width;
      doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);


      doc.setFont('NotoSansArabic');
      doc.setFontSize(18);
      doc.setTextColor(33, 140, 145);
       // Récupérer le nom du mois en arabe
      const monthNameArabic = monthsInArabic[selectedMonth];
      doc.text(` التقرير الشهري : شهر ${monthNameArabic}`, doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
 

      // Assurer que les données correspondent aux attentes
      const tableData = Object.keys(responseData).map(field => [
        responseData[field]["Institutions"] ? responseData[field]["Institutions"].join(', ') : '', // institutions
        ` عدد المستفيدين :   ${responseData[field]["Nombre beneficiare"] || 0}\n` +
        `عدد الأنشطة: ${responseData[field]["Activity Count"] || 0}\n`+
        `الأنشطة: ${responseData[field]["Custom Activities"] ? responseData[field]["Custom Activities"].join(', ') : ''}\n`,

        field // intervention fields
      ]);

      doc.autoTable({
        startY: imgHeight + 30,
        head: [['المؤسسات', 'الانجازات', 'مجالات التدخل']],
        body: tableData,
        theme: 'grid',
        styles: { font: 'NotoSansArabic', fontSize: 10 },
        headStyles: { fillColor: [33, 140, 145], textColor: [255, 255, 255], halign: 'right'},
        columnStyles: {
          0: { cellWidth: 30, halign: 'right' }, // المؤسسات
          1: { cellWidth: 'auto', halign: 'right' }, // الانجازات
          2: { cellWidth: 'auto', halign: 'right' }  // مجالات التدخل
        },
        didDrawPage: (data) => {
          doc.setTextColor(0, 0, 0);
        }
      });
      
      const pageHeight = doc.internal.pageSize.height;
      const signatureY = pageHeight - 30; // Position Y pour les signatures
    
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
      // Signature à droite
      doc.text(':  توقيع مستشار التوجيه', doc.internal.pageSize.width - 20, signatureY, { align: 'right' });
      // Sauvegarder le PDF
      doc.save('monthly-report.pdf');
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };

  const handleExportPDF2 = async () => {
    try {
      // Effectuer une requête pour récupérer les données depuis l'API
      const response = await fetch(
        `${API_BASE_URL}/api/fiche-technique/pdf/periodic-report?userId=${userId}&year=${selectedYearPeriodic}&cycle=${selectedCycle}`
      );
      const responseData = await response.json();

      // Créer le PDF
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      doc.addFileToVFS('NotoSansArabic-Regular.ttf', '/fonts/NotoSansArabic-Regular.ttf');
      doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');

      // Charger l'image
      const img = new Image();
      img.src = '/images/Photo1.PNG';
      await new Promise(resolve => {
           img.onload = resolve;
         });
  
      const imgWidth = 100;
      const imgHeight = (img.height * imgWidth) / img.width;
      doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);

      doc.setFont('NotoSansArabic');
      doc.setFontSize(18);
      doc.setTextColor(33, 140, 145);
      doc.text(` التقرير الدوري : ${selectedCycle} `, doc.internal.pageSize.width / 2,imgHeight+20, { align: 'center' });

      const tableData = Object.keys(responseData).map(field => [
        responseData[field]["Institutions"] ? responseData[field]["Institutions"].join(', ') : '', // institutions
        `عدد المستفيدين: ${responseData[field]["Nombre beneficiare"] || 0}\n` +
        `عدد الأنشطة: ${responseData[field]["Activity Count"] || 0} \n`+
        `الأنشطة: ${responseData[field]["Custom Activities"] ? responseData[field]["Custom Activities"].join(', ') : ''}`, // accomplishments
        field // intervention fields
      ]);

      doc.autoTable({
        startY:  imgHeight + 30,
        head: [['المؤسسات', 'الانجازات', 'مجالات التدخل']],
        body: tableData,
        theme: 'grid',
        styles: { font: 'NotoSansArabic', fontSize: 10 },
        headStyles: { fillColor: [33, 140, 145], textColor: [255, 255, 255] , halign: 'right' },
        columnStyles: {
          0: { cellWidth: 30, halign: 'right' }, // المؤسسات
          1: { cellWidth: 'auto', halign: 'right' }, // الانجازات
          2: { cellWidth: 'auto', halign: 'right' }  // مجالات التدخل
        },
        didDrawPage: (data) => {
          doc.setTextColor(0, 0, 0);
        }
      });
      // Définir la position pour les signatures et le texte central
            const pageHeight = doc.internal.pageSize.height;

      const margin = 20; // Marge à partir des bords 
      const signatureY = pageHeight - 30; // Position Y pour les signatures

      const centerTextY = signatureY - 10; // Position Y pour le texte central (ajustez selon besoin)
      //const signatureY = 150; // Y position pour les signatures (et texte central)

// Positions horizontales
const rightSignatureX = doc.internal.pageSize.width - margin; // Signature à droite
const leftSignatureX = margin; // Signature à gauche
const centerTextX = doc.internal.pageSize.width / 2; // Texte central (au milieu de la page)

    
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
     // Signature à droite
     doc.text(': توقيع مستشار التوجيه', rightSignatureX, signatureY, { align: 'right' });
     // Texte central
     doc.text(': توقيع المدير الإقليمي', centerTextX, signatureY, { align: 'center' }); 
    // Signature à gauche
    doc.text(': توقيع المفتش', leftSignatureX, signatureY, { align: 'left' });

      // Sauvegarder le PDF
      doc.save('periodic-report.pdf');
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
      <h2 style={{ textAlign: 'right' }}>التقرير</h2>

      <div className="button-container">
        <button
          className="btn btn-monthly"
          onClick={() => {
            setShowMonthlyReport(true);
            setShowPeriodicReport(false);
          }}
        >
          التقرير الشهري
        </button>

        <button
          className="btn btn-periodic"
          onClick={() => {
            setShowMonthlyReport(false);
            setShowPeriodicReport(true);
          }}
        >
          التقرير الدوري
        </button>
      </div>

      {showMonthlyReport && (
        <div className="form-group">
          <label htmlFor="yearMonthly" style={{ display: 'block', textAlign: 'right' }}>السنة</label>
          <select
            id="yearMonthly"
            value={selectedYearMonthly}
            onChange={(e) => setSelectedYearMonthly(e.target.value)}
            className="form-control"
          >
            <option value="" style={{ display: 'block', textAlign: 'right' }}>اختر السنة</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <label htmlFor="month" style={{ display: 'block', textAlign: 'right' }}>الشهر</label>
          <select
            id="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="form-control"
          >
            <option value="" style={{ display: 'block', textAlign: 'right' }}>اختر الشهر</option>
            {months.map(month => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <button className="btn btn-success" onClick={handleExportPDF1}>
           PDF تحميل على شكل
          </button>
        </div>
      )}

      {showPeriodicReport && (
        <div className="form-group">
          <label htmlFor="yearPeriodic" style={{ display: 'block', textAlign: 'right' }}>السنة</label>
          <select
            id="yearPeriodic"
            value={selectedYearPeriodic}
            onChange={(e) => setSelectedYearPeriodic(e.target.value)}
            className="form-control"
          >
            <option value="" style={{ display: 'block', textAlign: 'right' }} >اختر السنة</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <label htmlFor="cycle" style={{ display: 'block', textAlign: 'right' }}>الدورة</label>
          <select
            id="cycle"
            value={selectedCycle}
            onChange={(e) => setSelectedCycle(e.target.value)}
            className="form-control"
          >
            <option value="" style={{ display: 'block', textAlign: 'right' }}>اختر الدورة</option>
            {cycles.map(cycle => (
              <option key={cycle} value={cycle}>
                {cycle}
              </option>
            ))}
          </select>
          <button className="btn btn-success" onClick={handleExportPDF2}>
          PDF تحميل على شكل
          </button>
        </div>
      )}
    </div>
  );
};

export default Report;
