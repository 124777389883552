import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import { UserProvider } from './context/UserContext';


const App = () => {
  const [view, setView] = useState('login');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check authentication status on initial load
  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleAuth = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem('isAuthenticated', status);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    setView('login');
  };

  return (
    <UserProvider>
    <div>
      {!isAuthenticated ? (
        view === 'login' ? (
          <Login setAuth={handleAuth} showRegister={() => setView('register')} />
        ) : (
          <Register setAuth={handleAuth} showLogin={() => setView('login')} />
        )
      ) : (
        <Dashboard handleLogout={handleLogout} />
      )}
    </div>
    </UserProvider>
  );
};

export default App;
