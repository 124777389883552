import React, { useState } from 'react';
import { registerUser } from '../api/userApi';
import { useUser } from '../context/UserContext'; // Assure-toi d'importer useUser
import '../styles.css';
import imageSchool from '../Images/Photo1.PNG';

const Register = ({ setAuth, showLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const { setUserId } = useUser(); // Utilisation du contexte utilisateur

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }
    try {
      const response = await registerUser({ username, email, password });
      if (response.data) {
        setUserId(response.data.id); // Met à jour le userId dans le contexte
        setAuth(true); // Indique que l'utilisateur est authentifié
      } else {
        setError("Erreur lors de l'inscription.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data);
      } else {
        setError("Erreur lors de l'inscription.");
      }
    }
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-xl-5 ms-xl-auto px-lg-4 text-center text-primary">
          <img 
            className="img-fluid mb-4" 
            width="350" 
            src={imageSchool}            
            alt="" 
            style={{ transform: "rotate(0deg)" }} 
          />
          <h1 className="mb-4">Bienvenue dans votre<br className="d-none d-lg-inline" /> espace privé.</h1>
          <p className="text-muted arabic-text">  البوصلة هي منصة رقمية موجهة لمستشاري التوجيه التربوي . هي بمثابة لوحة قيادة للمساعدة على تنظيم ، تبسيط و أرشفة المهام المنجزة على شكل تقارير يومية (بطاقات تقنية) شهرية او دورية .  </p>
          <p className="text-muted arabic-text"> سنعمل على تطوير البوصلة ليصبح بمقدورها مساعدتك على تدبير مجالس الاقسام في نهاية السنة الدراسية و تقديم جميع الاحصائيات و المحاضر المطلوبة .</p>        </div>
        <div className="col-lg-6 px-lg-4">
          <div className="card">
            <div className="card-header px-lg-5">
              <div className="card-heading text-primary">Créer un compte</div>
              <div>C’est simple et rapide.</div>
            </div>
            <div className="card-body p-lg-5">
              <h3 className="mb-4">Bienvenue</h3>
              <p className="text-muted text-sm mb-5">Inscrivez-vous pour accéder à toutes les fonctionnalités.</p>
              {error && <div className="text-danger mb-3">{error}</div>}
              <form onSubmit={handleRegister}>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Nom d'utilisateur"
                    required
                  />
                  <label htmlFor="username">Nom d'utilisateur</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="floatingInput"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@example.com"
                    required
                  />
                  <label htmlFor="floatingInput">Email</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="floatingPassword"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                    required
                  />
                  <label htmlFor="floatingPassword">Mot de passe</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmer le mot de passe"
                    required
                  />
                  <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                </div>
                <div className="form-group">
                  <button className="btn btn-primary" id="register" type="submit" name="registerSubmit">Créer un compte</button>
                </div>
              </form>
            </div>
            <div className="card-footer px-lg-5 py-lg-4">
              <div className="text-sm text-muted">
                Vous avez déjà un compte ? 
                <button 
                  onClick={showLogin} 
                  className="btn btn-link p-0" 
                  style={{ textDecoration: 'underline', color: '#0d6efd' }}>
                  Connexion
                </button>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
